import {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useEffect,
  useCallback,
  useState,
  useRef,
} from 'react';

import {
  signOut as signOutNextAuth,
  signIn as signInNextAuth,
  getSession,
  useSession,
} from 'next-auth/react';
import { useTranslation } from 'next-i18next';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { differenceInDays } from 'date-fns';
import Store from 'lib/Store';
import mapReducerActions from 'utils/common/mapReducerActions';

import { useAlerts } from 'hooks/useAlerts';
import { useRouterMiddleware } from 'hooks/useRouterMiddleware';

import config from 'config';
import routes from 'config/routes';

import api from 'api';
import getUser from 'api/user/getUser';
import increaseTokenExpirationDate from 'api/user/increaseTokenExpirationDate';
import updateUserProperty from 'api/user/updateUserProperty';

import initialState from './initialState';
import * as UserReducer from './reducer';
import {
  IUseUserActionsType,
  IUseUserContextData,
  IUseUserProviderProps,
  IUseUserReducerType,
  LoadingActions,
  ISignInProps,
} from './types/reducer';

const authStore = Store(`${config.localStorageKey}-auth`);

const store = Store(`${config.localStorageKey}-user`);

const UseUserContext = createContext<IUseUserContextData>({} as IUseUserContextData);

const UseUserProvider: React.FC<IUseUserProviderProps> = ({ children }) => {
  const queryClient = useQueryClient();
  const { navigateTo } = useRouterMiddleware();
  const { data: session } = useSession();
  const { errorMessage, confirmMessage } = useAlerts();
  const { t } = useTranslation();

  const accessToken = useMemo(() => session?.user?.accessToken, [session]);

  const [loadingAction, setLoadingAction] = useState<LoadingActions>(null);

  // # Reducer
  const initialData: IUser = store.get() || initialState;
  const [state, dispatch] = useReducer(UserReducer.reducer, { ...initialData });
  const actions: IUseUserActionsType = useMemo(
    () => mapReducerActions(UserReducer.actions, dispatch),
    [],
  );
  const reducer: IUseUserReducerType = useMemo(() => ({ state, actions }), [actions, state]);

  // # Ao atualizar o state, atualiza a store
  useEffect(() => {
    if (reducer.state.email) store.set(reducer.state);
  }, [reducer.state]);

  // # Clear
  const clear = useCallback(() => {
    reducer.actions.setClear();
    store.clear();
    authStore.clear();
  }, [reducer.actions]);

  // Salva as informações do usuário
  const saveUserDataOnReducer = useCallback(
    (data: IUser) => {
      reducer.actions.setProfile(data);
    },
    [reducer.actions],
  );

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const getUserMutation = useMutation({
    mutationFn: getUser,
  });

  const { data: userData } = useQuery({
    queryKey: ['user-data', reducer.state.cacheKey],
    queryFn: () => getUser({}),
    enabled: !!accessToken,
    retry: 5,
    refetchOnWindowFocus: false,
  });

  const onDataChange = useCallback(
    async (paramData: IUser) => {
      // Se está logado, mas não tem informação na store, tem algo errado,
      // pega essa informação novamente
      if (paramData && paramData.id !== state.id) {
        saveUserDataOnReducer(paramData);
      }

      // Faz mais do que 30 dias que fez login? Se sim, aumenta o tempo de expiração do token
      try {
        const lastLogin = authStore.getItem('last-login');

        if (differenceInDays(new Date(), new Date(String(lastLogin))) > 30) {
          increaseTokenExpirationDate();
          authStore.setItem('last-login', new Date().toISOString());
        }
      } catch (error) {
        console.log(error);
      }
    },
    [saveUserDataOnReducer, state],
  );

  useEffect(() => {
    if (userData && userData !== 'expired-token') {
      onDataChange(userData);
    }
  }, [onDataChange, userData]);

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const getCompanyById = (
    companyId: string,
    companies: IUser_Company[],
  ): IUser_Company | undefined => {
    const company = companies.find((o) => o.placeId === companyId);

    if (company) return company;

    if (!company && companies.length > 0) return companies[0];

    return undefined;
  };

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const doSignOut = useCallback(() => {
    queryClient.invalidateQueries();
    signOutNextAuth();
    clear();

    return navigateTo(routes.auth.login);
  }, [clear, navigateTo, queryClient]);

  const handleSignOut = useCallback(
    (skipConfirmation?: boolean) => {
      if (skipConfirmation) return doSignOut();

      return confirmMessage({
        message: t('common:sure-want-to-leave'),
        onYesCallback: () => {
          doSignOut();
        },
      });
    },
    [confirmMessage, t, doSignOut],
  );

  const preStoreUserData = useCallback(
    async (token?: string) => {
      // # Pré preenche o reducer com as informações do usuário
      const userData = await getUserMutation.mutateAsync({ token });

      if (userData && userData !== 'expired-token') {
        if (userData.preferredCompanyId)
          api.defaults.headers.common['X-PersonAId'] = userData.preferredCompanyId;

        if (userData.preferredPlaceId) {
          api.defaults.headers.common['X-PlaceId'] = userData.preferredPlaceId;
        }

        saveUserDataOnReducer(userData);

        return userData;
      }

      delete api.defaults.headers.common.Authorization;
      doSignOut();
      errorMessage(t('common:validations:errors:invalid-user-data'));
      return null;
    },
    [doSignOut, errorMessage, getUserMutation, saveUserDataOnReducer, t],
  );

  const handleSignIn = useCallback(
    async (props: ISignInProps) => {
      const { data, ignoreRedirect, redirectURL } = props;

      clear();

      setLoadingAction('signin');

      const res: any = await signInNextAuth('credentials', {
        ...data,
        redirect: false,
        callbackUrl: `${window.location.origin}`,
      });

      if (res?.error) {
        setLoadingAction(null);
        return errorMessage(t('common:validations:errors:invalid-credentials'));
      }

      const createdSession = await getSession();
      const token = createdSession?.user?.accessToken;

      if (!token) {
        setLoadingAction(null);
        return errorMessage(t('common:validations:errors:invalid-credentials'));
      }

      api.defaults.headers.common.Authorization = `Bearer ${token}`;

      await preStoreUserData(token);

      setLoadingAction(null);

      // Se não tiver empresa, não permite entrar
      /* const userData = await preStoreUserData(token);
      if (!userData?.preferredCompanyId || !userData?.preferredPlaceId) {
        delete api.defaults.headers.common.Authorization;
        doSignOut();
        return errorMessage(t('common:validations:errors:no-company'));
      } */

      authStore.setItem('last-login', new Date().toISOString());

      // # Redirecionamento
      const redirectURLFromParam = redirectURL;
      const redirectURLFromStorage = authStore.getItem(`redirect`);

      let redirectToURL = null;

      // Prioriza redirect pela Storage
      if (redirectURLFromStorage) {
        redirectToURL = redirectURLFromStorage;
      } else if (redirectURLFromParam) redirectToURL = redirectURLFromParam;

      if (!ignoreRedirect) {
        authStore.removeItem(`redirect`);

        return navigateTo(
          redirectToURL && redirectToURL !== 'undefined' ? redirectToURL : routes.dashboard,
        );
      }
    },
    [errorMessage, navigateTo, preStoreUserData, t],
  );

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const selectedCompany = useMemo(
    () => getCompanyById(state?.preferredPlaceId || '', state.companies),
    [state.companies, state.preferredPlaceId],
  );

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const updateCompanySession = useCallback(async () => {
    // Ao chamar o getSession, por trás é chamado callback `async session(props)`lá no arquivo `[...nextauth].ts`
    // Nesse callback, é chamada a rota que pega as informações do usuário e verifica qual é a empresa que ele selecionou
    // Ai lá dentro é que a sessions é modificada
    await getSession();
  }, []);

  const oldCompanyId = useRef<string>('');

  useEffect(() => {
    // se a empresa selecionada mudar, atualiza ela na session
    if (accessToken && selectedCompany) {
      if (oldCompanyId.current !== selectedCompany?.companyId) {
        oldCompanyId.current = selectedCompany.companyId;
        updateCompanySession();
      }
    }
  }, [accessToken, selectedCompany, updateCompanySession]);

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleChangeCompany = useCallback(
    (newPlaceId: string) => {
      const company = state.companies.find((o) => o.placeId === newPlaceId);
      if (!company) return;

      // Atualiza as Props do usuário em plano de fundo
      updateUserProperty({
        key: 'PREFERRED-COMPANY-ID',
        value: company.companyId,
      });

      updateUserProperty({
        key: 'PREFERRED-PLACE-ID',
        value: company.placeId,
      });

      // Atualiza na store
      actions.setPreferredPlaceId(company.placeId);
      actions.setPreferredCompanyId(company.companyId);

      // Invalida as queries existentes para forçar refetch de todas
      queryClient.invalidateQueries();
    },
    [actions, queryClient, state.companies],
  );

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  // Ao carregar a página, verifica se está logado e então define o token no axios

  useEffect(() => {
    if (accessToken) {
      api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

      if (selectedCompany?.companyId)
        api.defaults.headers.common['X-PersonAId'] = selectedCompany.companyId;

      if (selectedCompany?.placeId)
        api.defaults.headers.common['X-PlaceId'] = selectedCompany.placeId;
    } else {
      delete api.defaults.headers.common.Authorization;
    }
  }, [accessToken, selectedCompany?.companyId, selectedCompany?.placeId]);

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const contextValue = useMemo(
    () => ({
      ...reducer.state,
      ...reducer.actions,

      selectedCompany,

      signOut: handleSignOut,
      signIn: handleSignIn,

      clear,

      handleChangeCompany,

      preStoreUserData,

      saveUserDataOnReducer,
      isUserLoggedIn: !!accessToken,

      loadingAction,
    }),
    [
      accessToken,
      clear,
      handleChangeCompany,
      handleSignIn,
      handleSignOut,
      loadingAction,
      preStoreUserData,
      reducer.actions,
      reducer.state,
      saveUserDataOnReducer,
      selectedCompany,
    ],
  );

  return <UseUserContext.Provider value={contextValue}>{children}</UseUserContext.Provider>;
};

const useUser = (): IUseUserContextData => {
  const context = useContext(UseUserContext);
  if (!context) throw new Error('useUser must be used within an UseUserProvider');
  return context;
};

export { UseUserProvider, useUser };
