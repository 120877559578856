/* eslint-disable prefer-destructuring */
import { produce } from 'immer';

import initialState from './initialState';
import { IUseUserReducerStateType, IUseUserSetProfileProps } from './types/reducer';

const actions: any = {
  setClear: (): IUseUserReducerStateType => initialState,

  setProfile: (
    state: IUseUserReducerStateType,
    params: IUseUserSetProfileProps,
  ): IUseUserReducerStateType => {
    const previousPreferredCompanyId = state.preferredCompanyId || null;
    const previousPreferredPlaceId = state.preferredPlaceId || null;

    state = {
      ...params,
      // Se já havia alguma empresa selecionada antes, mantém ela e ignora o que vier da api
      preferredPlaceId: previousPreferredPlaceId || params.preferredPlaceId,
      preferredCompanyId: previousPreferredCompanyId || params.preferredCompanyId,
    };

    return state;
  },

  setPreferredPlaceId: (
    state: IUseUserReducerStateType,
    newPlaceId: string,
  ): IUseUserReducerStateType => {
    if (state.companies && state.companies.length > 0) {
      let selectedCompany = state.companies.find((company) => company.placeId === newPlaceId);
      if (!selectedCompany) {
        selectedCompany = state.companies[0];
      }

      state.preferredPlaceId = selectedCompany.placeId;
    }

    return state;
  },

  setPreferredCompanyId: (
    state: IUseUserReducerStateType,
    newCompanyId: string,
  ): IUseUserReducerStateType => {
    if (state.companies && state.companies.length > 0) {
      let selectedCompany = state.companies.find((company) => company.placeId === newCompanyId);
      if (!selectedCompany) {
        selectedCompany = state.companies[0];
      }

      state.preferredCompanyId = selectedCompany.placeId;
    }

    return state;
  },
};

function reducer(
  state: IUseUserReducerStateType,
  action: IReducerGenericActionsType,
): IUseUserReducerStateType {
  return produce(state, (draftState: IUseUserReducerStateType) =>
    action.type ? actions[action.type](draftState, action.params) : draftState,
  );
}

export { actions, reducer };
