import { callAPI, mainAPIURL } from 'api/main';

interface IUpdateUserPropertyProps {
  key: string;
  value: string;
}

export default async ({ key, value }: IUpdateUserPropertyProps): Promise<IAPIReturn> => {
  try {
    const res = await callAPI({
      callId: 'updateUserProperty',
      path: `${mainAPIURL}/v2/person/property/add-or-update`,
      method: 'POST',
      params: {
        Key: key,
        Value: value,
      },
    });

    if (!res.error) return res;

    return {
      error: 'server',
      request: null,
    };
  } catch (ex) {
    return {
      error: 'server',
      request: null,
    };
  }
};
