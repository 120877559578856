const commonPermissions: Array<string> = ['cancel-purchase'];

const permissions: IPermissions = {
  default: commonPermissions,
  employee: [],
  owner: [
    'list-users',
    'revoke-user-access',
    'generate-company-code',
    'list-financial',
    'request-money',
    'update-financial-data',
    'edit-item',
    'pause-store',
  ],
};

export default permissions;
