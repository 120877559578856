import { IUseUserReducerStateType } from './types/reducer';

const initialState: IUseUserReducerStateType = {
  id: '',

  name: '',
  email: '',
  phone: '',

  companies: [],
  preferredPlaceId: undefined,
};

export default initialState;
