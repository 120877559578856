import { i18n } from 'next-i18next';

export const getMainUserRole = (roles: IUser_CompanyUserRoles[]): string => {
  const i18nDefault = i18n?.t('common:roles:default') || 'Usuário';
  const i18nOwner = i18n?.t('common:roles:owner') || 'Proprietário';
  const i18nEmployee = i18n?.t('common:roles:employee') || 'Operador';

  if (roles.includes('owner')) return i18nOwner;
  if (roles.includes('employee')) return i18nEmployee;

  return i18nDefault;
};
