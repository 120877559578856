import configPermissions from 'security/permissions';
import { getMainUserRole } from 'services/user-roles';
import capitalizeString from 'utils/common/capitalizeString';
import getValueFromObjectPropertyArray from 'utils/common/getValueFromObjectPropertyArray';
import { v4 as uuid } from 'uuid';

const userDefaultRoles: IUser_CompanyUserRoles[] = ['default'];

export const middlewareCompany = (data: any): IUser_Company => {
  const userRoleOnCompany = data?.Relationship?.Type ? data?.Relationship?.Type.toLowerCase() : '';

  const roles: IUser_CompanyUserRoles[] = [...userDefaultRoles, userRoleOnCompany]; // role padrão + o que vier da API

  // Define quais permissões o usuário vai ter com base no tipo
  const permissions: Array<string> = [];
  roles.map((role: string) => {
    if (configPermissions[role]) configPermissions[role].map((p) => permissions.push(p));
    return true;
  });

  let companyProps: IObjectProperty[] = [];
  let placeProps: IObjectProperty[] = [];

  if (data?.Person?.Properties && data?.Person?.Properties.length > 0) {
    companyProps = data?.Person?.Properties.map(middlewareObjectProperty);
  }

  if (data?.Place?.Properties && data?.Place?.Properties.length > 0) {
    placeProps = data?.Place?.Properties.map(middlewareObjectProperty);
  }

  let logo = `/images/lorem/default.jpg`;

  if (data?.Place?.Medias) {
    const logoMedia = data?.Place?.Medias.find((o: any) => o.Class === 'LOGO-IMAGE');

    if (logoMedia) {
      logo = logoMedia.Url;
    }
  }

  return {
    companyId: data?.Person?.Id || '',
    placeId: data?.Place?.Id || '',
    name: data?.Place?.Title ? capitalizeString(data.Place.Title) : '',
    description: data?.Place?.Description || '',
    location: data?.Place?.Location || '',
    logo,

    reportLink: getValueFromObjectPropertyArray(placeProps, 'XLS-REPORT-LINK', undefined),
    zipPayStoreId: getValueFromObjectPropertyArray(placeProps, 'ZIP_PAY_STORE_ID', ''),
    zipPayStoreToken: getValueFromObjectPropertyArray(placeProps, 'ZIP_PAY_STORE_TOKEN', ''),

    flags: {
      forcePrintOnOrderSuccess:
        getValueFromObjectPropertyArray(companyProps, 'FORCE-PRINT', 'FALSE') === 'true',
    },

    user: {
      roles,
      mainRole: getMainUserRole(roles),
      permissions,
    },
  };
};

interface IMiddlewareUserProps {
  userData: any;
  userCompanies: any[];
  userProps: IObjectProperty[];
}

export const middlewareUser = async ({
  userData,
  userCompanies,
  userProps,
}: IMiddlewareUserProps): Promise<IUser> => {
  const userId = userData?.Id || uuid();

  let preferredPlaceId = getValueFromObjectPropertyArray(
    userProps,
    'PREFERRED-PLACE-ID',
    undefined,
  );
  let preferredCompanyId = getValueFromObjectPropertyArray(
    userProps,
    'PREFERRED-COMPANY-ID',
    undefined,
  );

  let companies: IUser_Company[] = [];
  if (userCompanies && userCompanies.length > 0) {
    companies = userCompanies.map(middlewareCompany);
  }

  // Ordena as empresas por nome
  companies = companies.sort((a, b) => {
    const fa = a.name.toLowerCase();
    const fb = b.name.toLowerCase();

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });

  if (companies && companies.length > 0 && (!preferredPlaceId || !preferredCompanyId)) {
    preferredPlaceId = companies[0].placeId;
    preferredCompanyId = companies[0].companyId;
  }

  // O usuário ainda tem acesso ao PlaceId ou EmpresaId?
  const companyExists = companies.some((company) => company.companyId === preferredCompanyId);
  const placeExists = companies.some((company) => company.placeId === preferredPlaceId);

  if (companies && companies.length > 0 && (!companyExists || !placeExists)) {
    preferredPlaceId = companies[0].placeId;
    preferredCompanyId = companies[0].companyId;
  }

  // Só permite lojas cadastradas no zip pay
  const onZipPayCompanies = companies.filter((o) => o.zipPayStoreId !== '');

  // Retorno
  return {
    cacheKey: userId,

    id: userId,
    name: userData?.Name ? capitalizeString(userData.Name) : '',
    email: userData?.Email || '',
    phone: userData?.Phone || '',

    preferredPlaceId,
    preferredCompanyId,
    companies: onZipPayCompanies,
  };
};

export const middlewareObjectProperty = (data: any): IObjectProperty => ({
  key: data?.Key || '',
  value: data?.Value || '',
});
